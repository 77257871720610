* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
}

html {
  font-size: 16px;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  background: #f6f7f9;
}

a {
  text-decoration: none;
}

::placeholder {
  font-style: italic;
  font-size: 13px;
}
