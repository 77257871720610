.container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 4em 1em 1em 1em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: background 1s ease-in;
  z-index: 99;
}

.containerOpen {
  transition: background 0.3s ease-in;
  background: rgba(0, 0, 0, 0.1);
  transform: translateY(0%);
}

.containerClosed {
  transition-property: background, transform;
  transition-delay: 0.3s, 0.4s;
  transition-duration: 0.1s, 0.1s;
  background: rgba(0, 0, 0, 0);
  transform: translateY(-200%);
}

.wrapper {
  background: #fff;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  padding: 2em;
  transition: ease-in 1s;
  max-height: 35em;
  overflow: scroll;
}

.wrapperOpen {
  transition: 0.3s;
  transform: translateY(0%);
}

.wrapperClosed {
  transition: 0.3s;
  transform: translateY(-200%);
}
