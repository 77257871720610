.container {
  background: #f6f7f9;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
}
.header {
  position: absolute;
  top: 60px;
  right: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  align-items: center;
  align-content: center;
}
.row_info {
  composes: row;
  grid-template-columns: 1fr;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  font-size: 1em;
  color: #666;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  font-size: 1em;
  height: 2.5em;
  padding: 0 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
  width: 8em;
  font-weight: 500;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}
.delete_button {
  composes: button;
  background: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.message_label {
  display: grid;
  justify-content: center;
  align-items: center;
}
.messageRed {
  color: #dc3545;
}
