.container {
  min-height: 20em;
  min-width: 30em;
  max-width: 40em;
  width: 100%;
  max-height: 30em;
  overflow: scroll;
}

.title {
  font-weight: 500;
  font-size: 1.2em;
  padding: 0 0 1em 0;
  color: #666;
}

.item {
  font-size: 1em;
  display: flex;
  padding: 1em;
  width: 100%;
  cursor: pointer;
}

.item:nth-child(odd) {
  background: rgba(0, 0, 30, 0.03);
}

.loading {
  min-height: 20em;
  min-width: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading > svg {
  font-size: 3em;
  color: #999;
}
.button {
  font-size: 1em;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-left: 0.5em;
  cursor: pointer;
}
.footer_loader {
  display: flex;
  justify-content: center;
  margin: 1em 0em;
}
