.container {
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10em 1em 1em 1em;
  min-height: 100vh;
  font-size: 1em;
}

.form {
  width: 100%;
  max-width: 280px;
}

.message {
  font-size: 0.9em;
  font-weight: 500;
  padding: 1em 0;
  text-align: center;
}

.error {
  composes: message;
  color: red;
}

.succcess {
  composes: message;
  color: green;
}

.logo {
  width: 100%;
  padding: 1em 1em 3em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo > img {
  display: block;
  width: 100%;
}
