.container {
  background: #f6f7f9;
  min-height: 100vh;
  width: 20px !important;
}
.header {
  position: absolute;
  width: 65em;
  top: 55px;
  right: 1em;
  flex-wrap: wrap-reverse;
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 1em;
  padding: 0px;
  font-size: 14px;
  margin: 0px;
}
.header [type="text"] {
  width: 280px;
}
.filter_box {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-column-gap: 0.8em;
  margin-top: -10px;
}
.icons_panel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
}
.export_file:hover {
  color: black;
}
.search_input {
  display: flex;
  text-align: center;
  margin-bottom: -0.6em;
}
.search_icon {
  position: absolute;
  margin-top: 10px;
  margin-left: -25px;
  color: rgba(0, 0, 0, 0.6);
}
.icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.file_upload {
  composes: icon;
}
.file_download {
  composes: icon;
}
.composesIcon {
  composes: icon;
}

.icon:hover {
  color: rgba(0, 0, 0, 0.6);
}

.titleContainer {
  padding: 0;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
  font-size: 1.5em;
  font-weight: 500;
  position: relative;
}
.content {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 1em;
}
.overflow_list {
  height: 69vh;
  overflow: auto;
}
.list {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: auto;
  display: grid;
}
.listTop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.6em;
}
.listTop > button,
.listTop > a {
  font-size: 1em;
  height: 2em;
  padding: 0 1em;
  border-radius: 8px;
  background: #1a73e8;
  margin-left: 0.5em;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listTop > button > span,
.listTop > a > span {
  font-size: 0.9rem;
}
.list_flexGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 0.5em;
}
.listHeader {
  composes: list_flexGrid;
  background: rgba(207, 151, 151, 0.05);
}

.listHeader > div {
  width: 15em;
  padding: 1em 0.5em;
  text-align: left;
  font-weight: 500;
  font-size: 0.9em;
}
.listHeader > div:last-child {
  width: 4em;
  text-align: center;
}

.listHeaderSort {
  cursor: pointer;
  user-select: none;
}

.listRow {
  composes: list_flexGrid;
}
.listRow:nth-child(even) {
  background: rgba(207, 151, 151, 0.05);
}
.listRow > div {
  width: 15em;
  padding: 1em 0.5em;
  font-size: 0.9em;
  color: #666;
}
.listRow > div:last-child {
  cursor: default;
  width: 4em;
  text-align: center;
}
.listRowLink > div {
  font-size: 0.9em;
  color: #666;
}
.listRowSelectText {
  user-select: text;
  cursor: text;
}
.footer {
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.footer > button {
  color: #666;
  font-size: 1em;
  padding: 0.2em 1em;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.button {
  font-size: 1em;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
}
.load_more_button {
  composes: button;
  width: 30em;
  height: 3em;
}
.listHeader_delete_enable {
  border-radius: 5px;
  padding: 3px 7px;
  font-size: 18px;
  margin: -10px 0px;
  font-weight: lighter;
  background-color: #dc3545;
  border: red solid thin;
  cursor: pointer;
  color: #fff;
}
.listHeader_delete_disable {
  composes: listHeader_delete_enable;
  cursor: auto;
  opacity: 0.5;
  background-color: #ffffff;
  border: #949495 solid thin;
  color: #000;
}

.tooltip {
  position: relative;
  display: inline-block;
  z-index: 10;
  font-size: 15px;
}
.tooltip_export {
  composes: tooltip;
}
.tooltip_buttons {
  composes: tooltip;
}
.tooltip_export .tooltiptext {
  width: 100px !important;
  margin-left: -6em !important;
}
.tooltip_buttons .tooltiptext {
  width: 100px !important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 2em;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
