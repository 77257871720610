.leftBar {
  width: 15em;
  height: 100vh;
  background: #f4f5f7;
  position: sticky;
  top: 0;
  left: 0;
  align-self: flex-start;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1rem;
}

.options {
  height: 100%;
  width: 100%;
}

.leftBarButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.8em 1em 0.8em 1em;
  background: none;
  cursor: pointer;
  outline: 0;
}

.leftBarButton:hover {
  background: rgba(0, 0, 0, 0.1);
}
.leftBarButton > div:nth-child(1) {
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftBarButton > div:nth-child(1) > svg {
  font-size: 1.2rem;
  color: #666;
}

.leftBarButton > div:nth-child(2) {
  display: block;
  width: 100%;
  font-size: 1em;
  text-align: left;
  padding: 0 0 0 1em;
  font-weight: 500;
  color: #666;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.8rem;
  background: none;
  cursor: pointer;
  outline: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.logoContainer > span {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0 0 0 1rem;
  font-size: 1.3em;
  font-weight: bold;
  color: #666;
}

.logo {
  display: block;
  width: 1.3rem;
  object-fit: contain;
}

.usermenu {
  position: relative;
  width: 100%;
  padding: 0.5em;
  background: none;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.userMenuToggleButton {
  display: block;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  cursor: pointer;
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.userImg {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  border: 1px solid #fafafa;
}

.userMenuButtons {
  top: 0%;
  left: 100%;
  position: absolute;
  padding: 0 0;
  border-radius: 0.3em;
  background: #fff;
  box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.username {
  font-size: 1em;
  font-weight: bold;
  color: #666;
  padding: 0 0 0 1em;
  word-wrap: none;
  white-space: nowrap;
}

.userMenuButtons > button {
  font-size: 1em;
  padding: 0.5em 2em;
  display: block;
  cursor: pointer;
}
