.inputContainer {
  padding: 0 0 0.6em 0;
}

.inputLabel {
  display: flex;
  padding: 0 0 0.3em 0;
  font-size: 0.9em;
  color: #666;
}

.input {
  width: 100%;
  font-size: 1em;
  height: 2.4em;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  padding: 0 0.5em;
}

.input[type="textarea"] {
  min-height: 12em;
  padding: 0.5em;
}
