.container {
  background: #fff;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1em;
  width: 100%;
  align-self: flex-start;
  justify-self: flex-start;
}
.containerSection {
  padding: 1em;
  border-radius: 8px;
  width: 100%;
}
.header {
  position: absolute;
  top: 60px;
  right: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr;
  justify-self: center;
}
.rowTwo {
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr;
  justify-self: center;
  gap: 1em;
}
.row_title {
  composes: row;
  font-size: 16px;
  color: #666;
  margin: 0 0 1em 0;
}
.row_button {
  composes: row;
  grid-template-columns: 5fr 1fr;
  justify-items: end;
  align-items: center;
}
.toogleIcon {
  width: 40px !important;
  height: 40px;
  cursor: pointer;
}
.toogleOn {
  composes: toogleIcon;
  color: #15aabf;
}
.toogleOff {
  composes: toogleIcon;
  color: #adb5bd;
}
.sectionTitleContainer {
  width: 100%;
  margin: 1em 0em;
  padding: 0.5em 0 0.5em 0;
}
.sectionTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
  font-size: 1.2em;
  font-weight: 500;
  position: relative;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  font-size: 1em;
  height: 2.5em;
  padding: 0 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
  width: 8em;
  font-weight: 500;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  z-index: 9;
}
.delete_button {
  composes: button;
  background: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.inputContainer {
  padding: 0;
  width: 100%;
}

/* this is for react-datepicker's .react-datepicker-wrapper */
.inputContainer > div {
  display: block;
}

.labelContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inputLabel {
  display: flex;
  padding: 0;
  font-size: 0.9em;
  color: #666;
}

.input {
  width: 100% !important;
  box-sizing: border-box;
  font-size: 1em;
  height: 2.4em;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  padding: 0 0.5em !important;
  display: block !important;
  margin: 0 !important;
}

.helpButton {
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 1em;
  color: #232856;
  margin-left: 0.5em;
  outline: 0;
}
