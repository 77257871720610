.inputContainer {
  padding: 0 0 0.6em 0;
}
.input {
  font-size: 1em;
}

.input[type="textarea"] {
  min-height: 12em;
  padding: 0.5em;
}
.input[type="checkbox"] {
  min-width: 1.2rem;
}


.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3em 0.6em;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0.3em;
  min-height: 2.6em;
}

.text {
  color: #666;
  text-align: justify;
  padding: 0.2em 0 0.2em 1em;
  font-size: 0.8em;
  user-select: none;
}

.labelContainer {
  height: 1.2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inputLabel {
  display: flex;
  padding: 0 0 0.3em 0;
  font-size: 0.9em;
  color: #666;
}

.errorContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.2em;
}

.error {
  color: red;
  font-size: 0.9em;
}
