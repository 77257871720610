.inputContainer {
  padding: 0 0 0.6em 0;
}

.input {
  width: 100%;
  font-size: 1em;
  height: 2.4em;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  padding: 0 0.5em;
}

.inputError {
  border-color: red;
}

.helpButton {
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 1em;
  color: #232856;
  margin-left: 0.5em;
  outline: 0;
}

.container_style label {
  color: #dc3545;
}

.container_style input {
  border: solid red thin;
}

.labelContainer {
  height: 1.2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inputLabel {
  display: flex;
  padding: 0 0 0.3em 0;
  font-size: 0.9em;
  color: #666;
}

.errorContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.2em;
}

.error {
  color: red;
  font-size: 0.9em;
}
