.container {
  background: #f6f7f9;
  min-height: 100vh;
}

.header {
  padding: 3.8em 1em 0.5em 1em;
  margin: 0 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.titleContainer {
  padding: 0;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
  font-size: 1.5em;
  font-weight: 500;
  position: relative;
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 1em;
}
.dropAreaContainer {
  padding: 1em 1em 2em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropArea {
  width: 20em;
  padding: 2em 1em;
  border: 3px dashed rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: 100%;
  max-width: 40em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dropArea > svg {
  font-size: 5em;
  color: rgba(0, 0, 0, 0.3);
}
.dropArea > h2 {
  font-size: 1.2em;
  font-weight: 500;
  padding: 1.2em 0 2em 0;
  color: rgba(0, 0, 0, 0.8);
}
.dropArea > button {
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 1em;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  outline: 0;
  cursor: pointer;
}

.uploadButton {
  padding: 2em 0;
}

.uploadButton > button {
  font-size: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5em 1em;
  margin-top: 1em;
  border-radius: 8px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row:nth-child(odd) {
  background: rgba(0, 0, 0, 0.1);
}

.row > div {
  padding: 0.5em;
  width: 100%;
}

.list {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.listDoc {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: auto;
  height: 70vh;
}
.listOverflow {
  width: auto;
}
.listTop {
  position: absolute;
  top: 115px;
  right: 15px;
}
.listTop > button,
.listTop > a {
  font-size: 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 1em;
}
.listHeader {
  display: grid;
  grid-column-gap: 0.5em;
  background: rgba(207, 151, 151, 0.05);
  width: fit-content;
}

.listHeader > div {
  width: 17em;
  padding: 1em 0.5em;
  text-align: left;
  font-weight: 500;
  font-size: 0.9em;
}

.listHeader > div:first-child {
  cursor: default;
  width: 3em;
  text-align: center;
}

.listRow {
  width: 100%;
  display: grid;
  grid-column-gap: 0.5em;
  width: fit-content;
}

.listRow:nth-child(even) {
  background: rgba(207, 151, 151, 0.05);
}

.listRow > div:first-child {
  cursor: default;
  width: 3em;
  text-align: center;
}

.listRow > div {
  width: 17em;
  padding: 1em 0.5em;
  font-size: 0.9em;
}
.spinner {
  height: 2em;
  align-items: center;
}
.body {
  max-height: 500px;
  overflow-y: scroll;
}
