.header {
  font-size: 1em;
  height: 3.8em;
  background: #f6f7f9;
  display: flex;
  justify-content: flex-end;
  align-items: right;
}

.usermenuContainer {
  position: relative;
  width: auto;
  height: 2em;
  display: flex;
  justify-content: right;
  align-items: right;
  margin: 0.5em 1.2em;
}
.button {
  font-size: 1.2em;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.4);
  background: none;
  cursor: pointer;
  outline: 0;
}
.usermenu {
  position: absolute;
  width: 10em;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 2;
}
.usermenu > button {
  display: block;
  font-size: 1em;
  color: #666;
  padding: 1em;
  width: 100%;
  text-align: left;
  background: none;
  cursor: pointer;
  outline: none;
}
.user_header_name {
  margin-right: 10px;
}
