.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  font-size: 1em;
  height: 2.5em;
  padding: 0 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
  width: 8em;
  font-weight: 500;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}
.delete_button {
  composes: button;
  background: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.title {
  font-weight: 500;
  font-size: 1.2em;
  padding: 0 0 1em 0;
  color: #666;
}