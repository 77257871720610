.inputContainer {
  padding: 0 0 0.6em 0;
  width: 100%;
}

/* this is for react-datepicker's .react-datepicker-wrapper */
.inputContainer > div {
  display: block;
}

.labelContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inputLabel {
  display: flex;
  padding: 0 0 0.3em 0;
  font-size: 0.9em;
  color: #666;
}

.input {
  width: 100% !important;
  box-sizing: border-box;
  font-size: 1em;
  height: 2.4em;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  padding: 0 0.5em !important;
  display: block !important;
  margin: 0 !important;
}

.inputError {
  border-color: red;
}

.helpButton {
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 1em;
  color: #232856;
  margin-left: 0.5em;
  outline: 0;
}

.errorContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.2em;
}

.error {
  color: red;
  font-size: 0.9em;
}
