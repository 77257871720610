.container {
  background: #f6f7f9;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
}
.header {
  position: absolute;
  top: 60px;
  right: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  align-items: left;
  align-content: center;
}
.row_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  padding: 0.3em 0em;
  justify-items: start;
}
.row_action_info {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  font-size: 1em;
  color: #666;
}
.row_buttons {
  display: flex;
  grid-template-columns: 1fr;
  justify-content: flex-end;
}
.row_info > div {
  font-size: 0.9em;
  color: #666;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-template-rows: 1fr 1fr;
  margin-top: 0px;
}
.button {
  font-size: 1em;
  height: 2.5em;
  padding: 0 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
  min-width: 8em;
  font-weight: 500;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}
.delete_button {
  composes: button;
  background: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.message_box {
  height: 1.5em;
  margin: 1.5em 0px;
}
.message {
  font-weight: bold;
}
.messageRed {
  composes: message;
  color: #dc3545;
}
.row_back_button {
  grid-column: 5;
}
