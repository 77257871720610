.container {
  background: #f6f7f9;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
}

.header {
  position: absolute;
  top: 60px;
  right: 15px;
}

.titleContainer {
  padding: 0;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
  font-size: 1.5em;
  font-weight: 500;
  position: relative;
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 1em;
}
.list {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: hidden;
}
.listTop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.6em;
}
.listTop > button,
.listTop > a {
  font-size: 1em;
  height: 2em;
  padding: 0 1em;
  border-radius: 8px;
  background: #1a73e8;
  margin-left: 0.5em;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listTop > button > span,
.listTop > a > span {
  font-size: 0.9rem;
}
.listHeader {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  grid-column-gap: 0.5em;
  background: rgba(207, 151, 151, 0.05);
}

.listHeader > div {
  width: 100%;
  padding: 1em 0.5em;
  text-align: left;
  font-weight: 500;
  font-size: 0.9em;
}

.listRow {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  grid-column-gap: 0.5em;
  -webkit-user-drag: none;
}

.listRow:nth-child(even) {
  background: rgba(207, 151, 151, 0.05);
}

.listRow > div {
  width: 100%;
  padding: 1em 0.5em;
  font-size: 0.9em;
  color: #666;
}

.listRowSelectText {
  user-select: text;
  cursor: text;
}

.footer {
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.footer > button {
  color: #666;
  font-size: 1em;
  padding: 0.2em 1em;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.button {
  font-size: 1em;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-left: 0.5em;
  cursor: pointer;
}
.load_more_button {
  composes: button;
  width: 30em;
  height: 3em;
}
