.container {
  background: #f6f7f9;
  height: 100vh;
  padding: 9;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
}
