.container {
  background: #f6f7f9;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
}
.loader {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 72vh;
  font-size: 25px;
  font-weight: lighter;
}
