.header {
  padding: 0 1em 0 1em;
}

.titleContainer {
  padding: 0.5em 0 0.5em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
  font-size: 1.3em;
  font-weight: 500;
  position: relative;
}
