.container {
  padding: 1em;
}

@media only screen and (min-width: 600px) {
  .container {
    padding: 2em;
  }
}

.wrapper {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 2rem 1em;
}

@media only screen and (min-width: 600px) {
  .wrapper {
    padding: 2em;
  }
}

.header {
  padding: 0 0 2em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse;
}

.header .logo {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 600px) {
  .header {
    padding: 0 0 2em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .header .logo {
    margin-bottom: 0;
  }
}

.title {
  padding-top: 1em;
  font-size: 1.5em;
  font-weight: 500;
  color: #666;
}

.subtitle {
  font-size: 0.9em;
  color: #666;
}

.form {
  width: 100%;
}

.buttons {
  padding: 2em 0 0 0;
  display: flex;
  justify-content: flex-end;
}

.sendButton {
  cursor: pointer;
  padding: 0 2rem;
  background: #c5c325;
  border-color: #c5c325;
  color: #fff;
  max-width: 10rem;
  font-weight: bold;
  align-self: flex-end;
}

.activeAnotherSimCardButton {
  cursor: pointer;
  padding: 0 2rem;
  background: #c5c325;
  border-color: #c5c325;
  color: #fff;
  font-weight: bold;
  align-self: flex-end;
}

.backButton {
  cursor: pointer;
  padding: 0 2rem;
  background: #ccc;
  border-color: #ccc;
  color: #fff;
  font-weight: bold;
  margin-right: 1rem;
  max-width: 10rem;
}

.inputRow {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
}

.imeiRow {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 600px) {
  .inputRow {
    grid-template-columns: 1fr 1fr;
  }
}

.message {
  font-weight: bold;
  color: red;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 0em 2rem 0rem;
}

@media only screen and (min-width: 600px) {
  .success {
    padding: 2em 1em 2rem 1rem;
  }
}


.success > svg {
  font-size: 8em;
  color: green;
}
.success > div:nth-child(2) {
  font-size: 1.6em;
  padding: 0.5em;
  font-weight: 500;
  color: green;
}
.success > div:nth-child(3) {
  font-size: 1em;
  font-weight: 500;
  padding: 2em 1em 4em 1em;
  text-align: center;
  color: #555;
}
.success > button {
  width: auto;
  display: inline-block;
  word-wrap: none;
  white-space: nowrap;
  background: #c5c325;
  border-color: #c5c325;
  color: #fff;
  font-weight: bold;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 1em;
  flex-direction: column;
}

.copyright {
  margin-top: 2rem;
}



@media only screen and (min-width: 600px) {
  .footer {
    flex-direction: row;
  }
  .supportLink {
    margin: 0;
  }
  .copyright {
    margin: 0;
  }
}

.supportLink {
  color: #666;
  font-weight: 500;
  font-size: 1em;
  margin: 1rem 0;

}
.footer > div {
  color: #666;
  font-size: 1em;
}
.languages {
  width: 1.7em;
  margin-right: 0.5em;
  cursor: pointer;
}
.confirmRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  border-bottom: #ddd solid 1px;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.inlineBlock {
  display: inline-block;
}

.inlineBlockRight {
  display: inline-block;
  text-align: right;
}

.confirmTitle {
  color: #555;
}

.confirmText {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #555;
}
